import { navigate } from 'gatsby';
import React, { useEffect } from 'react';

const NotFoundPage = (): JSX.Element => {
  useEffect(() => {
    navigate('/');
  }, []);

  return (
    <div>
      <h1>(404) NotFound Page</h1>
    </div>
  );
};

export default NotFoundPage;
